$(document).on('turbolinks:load', function(){
  $('form').parsley();
  $(".alert").delay(3000).slideUp(1000, function(){
    $(".alert").alert('close');
  });
});
window.Parsley.addValidator('checkuniq', {
  validateString: function(value, field_name) {
    // var business_information_id = document.getElementById('user_business_information_id').value
    var xhr;
    xhr = $.ajax({
      url: '/users/checkuniq',
      dataType: 'json',
      method: 'POST',
      data: {
        field_name: field_name.split(' ')[0],
        value: value,
        model_name: field_name.split(' ')[1],
        id: field_name.split(' ')[2]
      },
      async: false,
      success: function(data) {
        return data;
      }
    });
    if (xhr.responseJSON.error === true) {
      return false;
    } else {
      return true;
    }
  }
});

window.Parsley.addValidator('fileextension', (function(value, requirement) {
  var allowedExtensions, fileExtension;
  fileExtension = value.split('.').pop();
  allowedExtensions = requirement.split(',');
  return $.inArray(fileExtension, allowedExtensions) > -1;
}), 32).addMessage('en', 'fileextension', 'Please enter a valid file');

