// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// JS packs
require("jquery")
require("bootstrap")
require('parsleyjs')
require('select2')
require("moment")

// Files Required
require('packs/common')
require('packs/custom')

// CSS packs
import 'bootstrap'
import '@fortawesome/fontawesome-free/js/all'
import 'select2/dist/js/select2.full'
import 'animate.css/animate.min.css'
import 'parsleyjs'


window.jQuery = $;
window.$ = $;
import moment from 'moment';
global.moment = moment;
